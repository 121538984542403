





















































import Vue from 'vue'
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { WARNING_TYPES, TWarningType } from '@/store/modules/site_warnings/types'

export default Vue.extend({
  name: 'WarningItem',
  data() {
    return {
      shouldRenderActionButton: [] // add item here to add the acknowledge button ie. EWarningType.Radar_Noise
    }
  },
  props: {
    id: Number,
    title: String,
    content: String,
    created_at: String,
    warning_type: {
      type: [Number, String] // TODO: make only Number when all warnings are moved to core-api
    },
    site_id: {
      type: [Number, String] // TODO: make only Number when all warnings are moved to core-api
    },
    timezone: {
      default: 'UTC',
      type: String
    },
    actions: {
      default: true,
      type: Boolean
    }
  },
  computed: {},
  methods: {
    ...mapActions('users', ['UPDATE_USER']),
    ...mapActions('site_warnings', {
      fetchWarnings: 'FETCH_SITE_WARNINGS',
      markAsRead: 'MARK_SITE_WARNING_AS_READ'
    }),
    async sendMarkAsRead(id: number): Promise<void> {
      await this.markAsRead({ site_id: parseInt(this.site_id), id: id })
    },
    stopPropagation(event: Event): void {
      event.stopPropagation()
    },
    getIcon(warning_type: TWarningType): string {
      switch (warning_type) {
        case WARNING_TYPES.High_Throttling:
          return 'error_outlined'
        case WARNING_TYPES.Radar_Noise:
          return 'settings'
        case WARNING_TYPES.Device_Version_Mismatch:
          return 'warning'
        case WARNING_TYPES.Drone_Opt_Id:
          return 'thermostat'
        case WARNING_TYPES.High_Disk_Space_Usage:
          return 'save'
        case WARNING_TYPES.Radar_Channel_Clash:
          return 'visibility'
        default:
          return 'check_all'
      }
    },
    formatDate(warning_date: string): string {
      return moment
        .tz(warning_date, this.timezone)
        .format('YYYY-MM-DD HH:mm:ss z')
    },
    // Returns a function that will determine the functionality of a click of the action button.
    getAction(warning_type: TWarningType): boolean {
      switch (warning_type) {
        case WARNING_TYPES.High_Throttling:
          return false
        case WARNING_TYPES.Radar_Noise:
          //redirect to installations
          return this.$router.push({ name: 'installations' })
        case WARNING_TYPES.Device_Version_Mismatch:
          return false
        case WARNING_TYPES.Drone_Opt_Id:
          return false
        case WARNING_TYPES.High_Disk_Space_Usage:
          return false
        case WARNING_TYPES.Radar_Channel_Clash:
          return this.$router.push({ name: 'installations' })
        default:
          break
      }
    }
  }
})
