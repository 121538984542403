










































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { WARNING_TYPES, TWarningType } from '@/store/modules/site_warnings/types'

import WarningContent from '@/components/Nav/TopNav/Menu/Warnings/Components/WarningContent.vue'
import { WARNING_DISPLAY_TYPE } from '@/store/modules/site_warnings/types'
const DEFAULT_TIMEZONE = 'UTC'

const DEBUG = false

const MOCK_WARNINGS = [
  {
    id: 1,
    content: 'Warning #1',
    read: false,
    title: 'Title #1',
    warning_type: 'high_throttling',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 2,
    content: 'Warning #2',
    read: false,
    title: 'Title #2',
    warning_type: 'radar_noise',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 3,
    content: 'Warning #3',
    read: false,
    title: 'Title #3',
    warning_type: 'device_version_mismatch',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 4,
    content: 'Warning #4',
    read: false,
    title: 'Title #4',
    warning_type: 'drone_opt_id',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 5,
    content: 'Warning #5',
    read: false,
    title: 'Title #5',
    warning_type: 'high_disk_space_usage',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 6,
    content: 'Warning #6',
    read: false,
    title: 'Title #6',
    warning_type: 'radar_channel_clash',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  },
  {
    id: 7,
    content: 'Warning #7',
    read: false,
    title: 'Title #7',
    warning_type: 'woohoo',
    created_at: '2023-03-23T03:05:03.43407Z',
    updated_at: '2023-03-23T03:05:03.434148Z',
    site_id: 1
  }
]

export default Vue.extend({
  name: 'WarningsMenu',
  components: { WarningContent },
  data() {
    return {
      WARNING_DISPLAY_TYPE,
      currentTab: 0,
    }
  },
  computed: {
    ...mapState('users', { status: 'status', user: 'user', client: 'client' }),
    ...mapGetters('sites', { activeSite: 'activeSite', activeSiteId: 'activeSiteId' }),
    ...mapGetters('site_warnings', {
      clearingSiteWarnings: 'clearingSiteWarnings',
      clearSiteWarningsLoading: 'clearSiteWarningsLoading'
    }),
    ...mapState('radar_perf_stats', { stats: 'stats' }),
    ...mapState('site_warnings', { site_warnings: 'site_warnings', site_warnings_history: 'site_warnings_history' }),
    siteWarnings() {
      return DEBUG ? MOCK_WARNINGS : this.site_warnings
    },
    siteWarningsHistory() {
      return DEBUG ? MOCK_WARNINGS : this.site_warnings_history
    },
    hasWarnings(): boolean {
      return this.siteWarnings?.length > 0 || false
    },
    timezone(): string {
      return (this.activeSite && this.activeSite?.timezone) || DEFAULT_TIMEZONE
    }
  },
  methods: {
    ...mapActions('users', ['UPDATE_USER']),
    ...mapActions('site_warnings', {
      fetchWarnings: 'FETCH_SITE_WARNINGS',
      clearWarnings: 'MARK_ALL_SITE_WARNING_AS_READ'
    }),
    ...mapMutations('site_warnings', {
      setClearingSiteWarnings: 'SET_CLEARING_SITE_WARNINGS',
      setClearSiteWarningsLoading: 'SET_CLEAR_SITE_WARNINGS_LOADING'
    }),

    openDialog(name: string): void {
      this.$bus.$emit('openDialog', name)
    },

    stopPropagation(event: MouseEvent): void {
      event.stopPropagation()
    },
    async clearAllSiteWarnings(): Promise<void> {
      this.setClearingSiteWarnings(true)
      this.setClearSiteWarningsLoading(true)
      await this.clearWarnings(this.activeSite.id)
      this.setClearingSiteWarnings(false)
      this.setClearSiteWarningsLoading(false)
    }
  },
  watch: {
    site_warnings: {
      handler: function(): void {
        this.$emit('update:warnings', this.hasWarnings)
      },
      immediate: true
    },
    activeSiteId(e: string) {
      let siteId = parseInt(e)
      if (isNaN(siteId)) return


      //refresh when active site is changed.
      this.fetchWarnings({
        site_id: siteId,
        last_2_days: false,
        read: false
      })
      // TODO: remove me and call me only when accessing this component (can't do until all warnings are in the API)
      this.fetchWarnings({
        site_id: siteId,
        last_2_days: true,
        read: true
      })
    }
  }
})
