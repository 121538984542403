











































import Vue from 'vue'
import WarningItem from '@/components/Nav/TopNav/Menu/Warnings/Components/WarningItem.vue'
import {
  ISiteWarning, TWarningDisplayType, WARNING_DISPLAY_TYPE
} from '@/store/modules/site_warnings/types'

const props = {
  items: {
    type: Array as () => ISiteWarning[],
    default: () => []
  },
  activeSiteId: {
    type: [String, Number],
    required: true
  },
  type: {
    type: String as () => TWarningDisplayType,
    default: WARNING_DISPLAY_TYPE.WARNING
  },
  timezone: {
    type: String,
    default: 'UTC'
  }
}

export default Vue.extend({
  name: 'WarningContent',
  components: { WarningItem },
  props,
  data() {
    return {
      WARNING_DISPLAY_TYPE,
      noWarningText: 'No System Warnings'
    }
  },
  computed: {
    hasItems(): boolean {
      return this.items.length > 0
    },
    sortedItems: function() {
      const itemsClone: ISiteWarning[] = [...this.items]
      return itemsClone.sort((a: ISiteWarning, b: ISiteWarning) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
    }
  }
})
